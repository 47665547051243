import {
  PENDING_AMAZON_SELLER_ACCOUNTS_ACTION,
  SET_AMAZON_SELLER_ACCOUNTS_DATA,
  SET_AMAZON_SELLER_ACCOUNT_SELECTOR_DATA
} from 'constants/action_types/amazon_seller_accounts'
import {
  ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS,
  ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE,
  ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE
} from 'constants/action_types/accounts/amazonSellerAccounts'
import { SELECT_AMAZON_SELLER_ACCOUNT } from 'constants/action_types/global'
import { SET_AMAZON_SELLER_ACCOUNT_SELECTED_COUNTRY } from 'constants/action_types/product'

import { removeNonAmazonMarketplaces } from 'helpers/mws'

export const initialState = {
  isLoading: false,

  amazonSellerAccounts: [],

  amazonSellerAccountSelector: {
    selectedAccount: undefined,
    selectedCountry: undefined,
    isLoading: false
  },

  sellerDataAvailable: true
}

function removeAmazonSellerAccount(state, accountId) {
  const result = { ...state }

  result.amazonSellerAccounts = result.amazonSellerAccounts.filter(
    account => account.id !== accountId
  )

  return result
}

function setActiveMarketplace(state, accounts) {
  if (
    Array.isArray(accounts) &&
    accounts.length > 0 &&
    accounts[0].countries.length > 0
  ) {
    let selectedCountry
    let selectedAccount

    const success = !!accounts.find(account => {
      return !!account.countries.find(c => {
        if (c.is_default) {
          selectedAccount = account
          selectedCountry = c
          return true
        }
        return false
      })
    })

    // if we didn't find a default account - just use the first thing in the array
    if (!success) {
      // Select first marketplace by default when loading the country list for the first time
      selectedAccount = accounts[0]
      selectedCountry = selectedAccount.countries[0]
    }

    return {
      ...state.amazonSellerAccountSelector,
      error: false,
      selectedAccount,
      selectedCountry
    }
  }

  // If we don't have accounts, we need to revert this to new values
  // otherwise it will maintain the ones from the last session
  return {
    selectedAccount: undefined,
    selectedCountry: undefined,
    isLoading: false,
    error: false
  }
}

function amazonSellerAccounts(state = initialState, action) {
  switch (action.type) {
    case PENDING_AMAZON_SELLER_ACCOUNTS_ACTION:
      return {
        ...state,
        isLoading: true
      }
    case SET_AMAZON_SELLER_ACCOUNTS_DATA:
      return {
        ...state,
        amazonSellerAccounts: action.payload.amazonSellerAccounts,
        amazonSellerAccountSelector:
          action.payload.amazonSellerAccountSelector ||
          setActiveMarketplace(state, action.payload.amazonSellerAccounts),
        isLoading: false,
        sellerDataAvailable: action.payload.sellerDataAvailable
      }
    case SET_AMAZON_SELLER_ACCOUNT_SELECTOR_DATA:
      return {
        ...state,
        amazonSellerAccountSelector: action.payload
      }
    case SELECT_AMAZON_SELLER_ACCOUNT: {
      return {
        ...state,
        amazonSellerAccountSelector: {
          ...state.amazonSellerAccountSelector,
          selectedAccount: action.payload.account,
          selectedCountry: action.payload.country
        }
      }
    }
    case SET_AMAZON_SELLER_ACCOUNT_SELECTED_COUNTRY: {
      return {
        ...state,
        amazonSellerAccountSelector: {
          ...state.amazonSellerAccountSelector,
          selectedCountry: action.payload
        }
      }
    }
    case ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS: {
      const accountId = action.payload
      let amazonSellerAccountSelector = { ...state.amazonSellerAccountSelector }

      if (state.amazonSellerAccountSelector.selectedAccount.id === accountId) {
        // update currently selected account
        amazonSellerAccountSelector = {
          ...state.amazonSellerAccountSelector,
          selectedAccount: {
            ...state.amazonSellerAccountSelector.selectedAccount,
            has_ppc_access: true
          }
        }
      }

      // update account entry in an array of seller accounts
      const amazonSellerAccounts = state.amazonSellerAccounts.map(account => {
        if (account.id === accountId) {
          account = {
            ...account,
            has_ppc_access: true
          }
        }
        return account
      })

      // return state with updated entries
      return {
        ...state,
        amazonSellerAccountSelector,
        amazonSellerAccounts
      }
    }
    case ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE: {
      const amazonSellerAccounts = state.amazonSellerAccounts.map(account => {
        if (account.id !== action.payload.id) {
          return {
            ...account,
            // if the returned account is now the default - unset the default account on everything else
            is_default: action.payload.is_default ? false : account.is_default,
            countries: account.countries.map(country => {
              // if the account isn't default - no point checking each country - just clear them out
              if (action.payload.is_default) {
                country.is_default = false
              }
              return country
            })
          }
        }
        const filteredData = removeNonAmazonMarketplaces(action.payload)
        return {
          ...account,
          ...filteredData
        }
      })

      return {
        ...state,
        amazonSellerAccountSelector: setActiveMarketplace(
          state,
          amazonSellerAccounts
        ),
        amazonSellerAccounts
      }
    }
    case ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE:
      return removeAmazonSellerAccount(state, action.payload.accountId)
    default:
      return state
  }
}

export default amazonSellerAccounts
